import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const split = cva('grid', {
  variants: {
    gap: {
      none: 0,
      xs: ['gap-0.5'],
      sm: ['gap-1'],
      md: ['gap-2'],
      lg: ['gap-4'],
      xl: ['gap-8'],
      xxl: ['gap-16'],
    },
    fraction: {
      '1': 'grid-cols-1',
      '1/4': 'grid-cols-1/4',
      '1/3': 'grid-cols-1/3',
      '1/2': 'grid-cols-1/2',
      '2/3': 'grid-cols-2/3',
      '3/4': 'gird-cols-3/4',
      'auto-start': 'grid-cols-auto-start',
      'auto-end': 'grid-cols-auto-end',
    },
  },
  // compoundVariants: [{ gap: 'md', align: 'start', class: 'uppercase' }],
  defaultVariants: {
    gap: 'none',
    fraction: '1/2',
  },
});

export interface SplitProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof split> {}

export const Split: React.FC<SplitProps> = ({
  className,
  gap,
  fraction,
  ...props
}) => (
  <div className={twMerge(split({ gap, fraction, className }))} {...props} />
);
